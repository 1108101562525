import React, { useState } from 'react';
import { Form, Row, Col, Card, Button, Alert } from 'react-bootstrap';

const SnowDayForm = () => {
  // State to store the postal code input
  const [postalCode, setPostalCode] = useState('');
  // State to store the result ("yes" or "no")
  const [result, setResult] = useState('');
  // State to handle error message
  const [error, setError] = useState('');

  // Regular expressions for validation
  const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  const isValidPostalCode = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the form from refreshing the page

    // Check if the input matches either of the regular expressions
    if (isValidZip.test(postalCode) || isValidPostalCode.test(postalCode)) {
      setResult('No'); // Set result to "No" if validation passes
      setError(''); // Clear any previous error messages
    } else {
      setResult(''); // Set result to blank if validation fails
      setError('Invalid postal or zip code.'); // Provide error feedback
    }
  };

  return (
    <Card style={{ backgroundColor: '#EEEEEE', padding: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Row>
            <Col xs={12} lg={8}>
              <Form.Label>Zip Code/Postal Code</Form.Label>
              <Form.Control
                type="text"
                name="postal_code"
                placeholder="Enter Postal Code"
                value={postalCode}
                onChange={e => setPostalCode(e.target.value)}
                style={{ margin: '0 15px' }}
              />
            </Col>
            <Col xs={12} lg={4}>
              <Button type="submit" style={{ margin: '0 15px' }}>
                Submit
              </Button>
            </Col>
          </Row>
          {error && <Row style={{ marginTop: '10px' }}>
            <Col xs={12}>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>}
          <Row style={{ marginTop: '10px' }}>
            <Col xs={12}>
              <Form.Label>Will I have a snow day tomorrow?</Form.Label>
              <Form.Control
                readOnly
                value={result}
                style={{ marginTop: '15px' }}
              />
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </Card>
  );
};

export default SnowDayForm;
