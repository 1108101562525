import React from 'react';
// import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SnowDayForm from './components/SnowDayForm';

function App() {
  return (
    <div>
      <div style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
        <h1> Snow Day Calculator </h1>
      </div>
      <SnowDayForm/>
    </div>
  );
}

export default App;
